import styled, {CSSObject} from 'styled-components';
import {Theme} from '../../theme/Theme';
import {Dictionary} from '../../types/Dictionary';

interface FlexProps {
  /** Css prop for flex-direction */
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  /** Css prop for justify-content */
  justifyContent?: 'center' | 'space-between' | 'flex-start' | 'flex-end';
  /** Css prop for align-items */
  alignItems?:
    | 'center'
    | 'space-between'
    | 'flex-start'
    | 'flex-end'
    | 'stretch';
}

interface BreakpointGroupProps extends FlexProps {
  breakpoint: number;
}

export interface StyledGroupProps extends FlexProps {
  breakpointProps?: BreakpointGroupProps;
}

const marginStyles: Dictionary<CSSObject> = {
  'row': {
    margin: 0,
    marginRight: Theme.space[3],
  },
  'row-reverse': {
    margin: 0,
    marginLeft: Theme.space[3],
  },
  'column': {
    margin: 0,
    marginBottom: Theme.space[4],
  },
  'column-reverse': {
    margin: 0,
    marginTop: Theme.space[4],
  },
};

export const StyledGroup = styled.div<StyledGroupProps>(
  {
    display: 'flex',
    flexWrap: 'nowrap',
    flexBasis: 'auto',
  },
  ({flexDirection, justifyContent, alignItems}) => ({
    flexDirection,
    justifyContent,
    alignItems,
    '> *': marginStyles[flexDirection!],
    '> *:last-child': {
      margin: 0,
    },
    'h3, h4, h5, h6': {
      marginBottom: `${Theme.space[2]}px !important`,
    },
  }),
  ({breakpointProps}) =>
    breakpointProps
      ? {
          [`@media screen and (min-width: ${breakpointProps.breakpoint}px)`]: {
            'justifyContent': breakpointProps.justifyContent,
            'flexDirection': breakpointProps.flexDirection,
            '> *': marginStyles[breakpointProps.flexDirection!],
            '> *:last-child': {
              margin: 0,
            },
          },
        }
      : {}
);
