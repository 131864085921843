import {HTMLProps, HTMLAttributes} from 'react';
import styled, {css, keyframes} from 'styled-components';
import {Theme} from '../../theme/Theme';
import {CardStyle} from './Card';

const {borderWidths, colours} = Theme;

export interface StyledCardProps extends HTMLAttributes<HTMLElement> {
  /** Css height value */
  height?: string;

  /** Css background colour of the containing div */
  colour?: string;

  /** Colour used in a gradient style card */
  gradientColours?: string[];

  /** animate the card in */
  animate?: boolean;

  /** defines which card style to use */
  cardStyle?: CardStyle;
}

const getTextColour = (backgroundColor?: string) =>
  backgroundColor === colours.yellow || backgroundColor === colours.white
    ? colours.black
    : colours.white;

const animateBar = keyframes`
  to {
    background-color: ${Theme.colours.grey};
  }
`;

export const StyledCard = styled.article<StyledCardProps>(
  {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: Theme.fonts.body,
    backgroundColor: Theme.colours.white,
    width: '100%',
    flexBias: 'auto',
  },
  ({
    height,
    colour,
    cardStyle = CardStyle.SOLID,
    gradientColours = [Theme.colours.yellow, Theme.colours.orange],
    animate,
  }) => {
    let styles = css``;

    switch (cardStyle) {
      case CardStyle.BORDER:
        styles = css`
          color: ${getTextColour(colours.grey)};
          background: ${colours.grey};
          border-bottom: solid ${colour} ${borderWidths[3]}px;
        `;
        break;

      case CardStyle.SOLID:
        styles = css`
          color: ${getTextColour(colour)};
          background: ${colour};
        `;
        break;

      case CardStyle.GRADIENT:
        styles = css`
          color: ${getTextColour(colour)};
          background: linear-gradient(125deg, ${gradientColours.join(',')});
        `;
        break;

      case CardStyle.OUTLINE:
        styles = css`
          border: solid ${colour} ${borderWidths[0]}px;
          background: ${Theme.colours.transparent};
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: ${colour};
          }
        `;
        break;

      case CardStyle.THICK_TOP:
        styles = css`
          border: solid ${colour} ${borderWidths[0]}px;
          border-top-width: ${borderWidths[3]}px;
          background: ${Theme.colours.transparent};
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: ${colour};
          }
        `;
        break;
    }

    return css`
      height: ${height};
      ${styles};
      ${animate
        ? css`
            animation: ${animateBar} 400ms ease-out forwards;
          `
        : ''};
      animation-delay: 200ms;
      li {
        height: 20px;
      }
    `;
  }
);

interface FlexProps {
  /** Css align-items value */
  alignItems?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit';

  /** Css justify-content value */
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'initial'
    | 'inherit';

  /** Css flex-direction value*/
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
}

interface BreakpointFlexProps extends FlexProps {
  breakpoint: number;
}

export interface CardBodyProps extends FlexProps {
  breakpointProps?: BreakpointFlexProps;
}

export const CardBody = styled.div<CardBodyProps>(
  {
    display: 'flex',
    position: 'relative',
    padding: Theme.space[5],
    height: '100%',
  },
  ({alignItems, justifyContent, flexDirection}) => ({
    alignItems,
    justifyContent,
    flexDirection,
  }),
  ({breakpointProps}) =>
    breakpointProps
      ? {
          [`@media screen and (min-width: ${breakpointProps.breakpoint}px)`]: {
            alignItems: breakpointProps.alignItems,
            justifyContent: breakpointProps.justifyContent,
            flexDirection: breakpointProps.flexDirection,
          },
        }
      : {}
);
