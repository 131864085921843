import React, {FunctionComponent, HTMLAttributes} from 'react';
import {StyledGroup, StyledGroupProps} from './Group.styles';

interface GroupProps extends HTMLAttributes<HTMLDivElement>, StyledGroupProps {}

export const Group: FunctionComponent<GroupProps> = ({
  className = '',
  children,
  flexDirection = 'column',
  justifyContent = 'center',
  alignItems = 'stretch',
  ...rest
}) => (
  <StyledGroup
    {...rest}
    flexDirection={flexDirection}
    justifyContent={justifyContent}
    alignItems={alignItems}
  >
    {children}
  </StyledGroup>
);
