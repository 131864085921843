import {RefObject, useEffect, useRef, useState} from 'react';

export const useBeenOnScreen = <T extends HTMLElement>(): [
  boolean,
  RefObject<T>
] => {
  const [beenOnScreen, setBeenOnScreen] = useState(false);
  const ref = useRef<T>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (window.innerHeight - entry.boundingClientRect.top > 0) {
        setBeenOnScreen(true);
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
      return () => observer.disconnect();
    } else {
      console.warn('using useBeenOnScreen without supplying a ref');
    }
  });
  return [beenOnScreen, ref];
};
