import React, {FunctionComponent} from 'react';
import {
  StyledCard,
  CardBody,
  StyledCardProps,
  CardBodyProps,
} from './Card.styles';
import {Theme} from '../../theme/Theme';
import {useBeenOnScreen} from '../../hooks/useBeenOnScreen';

export enum CardStyle {
  SOLID,
  BORDER,
  GRADIENT,
  OUTLINE,
  THICK_TOP,
}

interface CardProps extends StyledCardProps, CardBodyProps {}

export const Card: FunctionComponent<CardProps> = ({
  children,
  height = 'auto',
  alignItems = 'flex-start',
  justifyContent = 'flex-start',
  flexDirection,
  colour = Theme.colours.yellow,
  cardStyle,
  gradientColours,
  breakpointProps,
  ...props
}) => {
  const [animate, ref] = useBeenOnScreen<HTMLHeadingElement>();

  return (
    <StyledCard
      {...props}
      height={height}
      colour={colour}
      cardStyle={
        cardStyle
          ? cardStyle
          : gradientColours
          ? CardStyle.GRADIENT
          : CardStyle.SOLID
      }
      gradientColours={gradientColours}
      animate={cardStyle === CardStyle.BORDER && animate}
      ref={ref}
    >
      <CardBody
        alignItems={alignItems}
        justifyContent={justifyContent}
        flexDirection={flexDirection}
        breakpointProps={breakpointProps}
      >
        {children}
      </CardBody>
    </StyledCard>
  );
};
