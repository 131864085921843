import React, {FunctionComponent} from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {graphql} from 'gatsby';
import {Card, CardStyle} from '../components/Card/Card';
import {Theme} from '../theme/Theme';
import {Group} from '../components/Group';
import {GutterMaxWidth, Gutters} from '../components/Spacing/Gutters';
import {FooterContent} from '../types/FooterContent';

interface VersionPageProps {
  pageContext: {
    contentfulFooterContent: FooterContent;
    customerPortalBaseUrl: string;
  };
  data: any;
}

const Version: FunctionComponent<VersionPageProps> = ({
  pageContext: {contentfulFooterContent, customerPortalBaseUrl},
  data,
}) => (
  <Layout
    {...contentfulFooterContent}
    customerPortalBaseUrl={customerPortalBaseUrl}
  >
    <SEO title="Version" />
    <Gutters maxWidth={GutterMaxWidth.VERY_SMALL} padding>
      <Card cardStyle={CardStyle.BORDER} colour={Theme.colours.blue}>
        <Group>
          <h3>Version</h3>
          <span data-testid="version">
            {data.site.siteMetadata.buildVersion}
          </span>
        </Group>
      </Card>
    </Gutters>
  </Layout>
);

export const pageQuery = graphql`
  query VersionPageQuery {
    site {
      siteMetadata {
        buildVersion
      }
    }
  }
`;

export default Version;
